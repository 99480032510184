/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header/Header.jsx"
import Footer from "./Footer/Footer.jsx"

import "./layout.css"

const Layout = ({ children }) => {

  const data = useStaticQuery(
    graphql`
      query {
        logo: file(relativePath: { eq: "logo_white.png" }) {
          childImageSharp {
            fluid(maxWidth: 380) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)

  const logoSrc = data.logo.childImageSharp.fluid;

  return (
    <>
      <Header logoSrc={logoSrc} />
      <div style={{ width: '100%', borderBottom: '1px solid rgba(66, 66, 66, 0.6)' }} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        {children}
      </div>
      <div style={{ width: '100%', borderBottom: '1px solid rgba(66, 66, 66, 0.6)' }} />
      <Footer logoSrc={logoSrc} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
