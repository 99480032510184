import React from 'react';
import Img from 'gatsby-image';
import styles from './Footer.module.css';
import { FooterStyled, ListWrapper, ListTitle, FooterList, ListItem } from './Components';
import { LINKS } from './helper';
import { v4 as uuidV4 } from 'uuid';

const Footer = ({ logoSrc = '' }) => {
  return (
    <FooterStyled>
      <Img fluid={logoSrc} className={styles.logo} alt={'Yieldscanner Crypto Research'} />
      <ListWrapper>
        <ListTitle> Useful Links </ListTitle>
        <FooterList>
          {LINKS.map(item => {
            return (
              <ListItem key={uuidV4()}>
                <a href={item.href}>
                  {item.label}
                </a>
              </ListItem>
            )
          })}
        </FooterList>
      </ListWrapper>
    </FooterStyled>
  )
};


export default Footer;