import styled from 'styled-components'

const LogoWrapper = styled.div`
  margin: 0 auto;

  @media(min-width: 769px) {
    margin: 0 auto 0 0;
  }
`

const ContactWrapper = styled.div`
  display: none;

  @media(min-width: 769px) {
    display: block;
    margin-left: auto;
    margin-right: 56px;
    font-size: 16px;
    font-weight: lighter;
  }
`

export { LogoWrapper, ContactWrapper };