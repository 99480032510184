import styled from 'styled-components';

const FooterStyled = styled.footer`
  max-width: 1080px;
  padding: 2em 1em 1em 1em;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media(min-width: 600px) {
    flex-direction: row;
    align-items: flex-start;
  }
`

const ListWrapper = styled.div`
  margin: 0 auto;
  padding-left: 42px;

  @media(min-width: 600px) {
    padding-left: 8px;
  }
`

const ListTitle = styled.p`
  color: #FFFFFF;
  text-transform: capitalize;
`

const FooterList = styled.ul`
  color: #4300D2;
  list-style: none;
  margin-left: 0;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  font-size: 14px;

  @media(min-width: 480px) {
    font-size: 16px;
  }
`

const ListItem = styled.li`
  padding-right: 24px;
`

export { FooterStyled, ListWrapper, FooterList, ListTitle, ListItem }