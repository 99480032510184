// import PropTypes from "prop-types"
import React from "react"
import AppBar from '@material-ui/core/AppBar';
import styles from './Header.module.css';
import Img from "gatsby-image";
import { LogoWrapper, ContactWrapper } from './Components';

const Header = ({ logoSrc }) => {
  return (
    <AppBar position="relative" classes={{ root: styles.header }}>
      <LogoWrapper>
        <Img fluid={logoSrc} className={styles.logo} alt={'Yieldscanner crypto research'} />
      </LogoWrapper>
      <ContactWrapper>
        ys@yieldscanner.com
      </ContactWrapper>
    </AppBar >
  )
}

export default Header
