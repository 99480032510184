export const LINKS = [
  {
    href: '#',
    label: 'Useful link 1',
    external: true
  },
  {
    href: '#',
    label: 'Useful link 2',
    external: true
  },
  {
    href: '#',
    label: 'Useful link 3',
    external: true
  },
  {
    href: '#',
    label: 'Useful link 4',
    external: false
  },
  {
    href: '#',
    label: 'Useful link 5',
    external: false
  },
  {
    href: '#',
    label: 'Useful link 6',
    external: false
  }
]